export const useNavigationStore = defineStore('navigation', () => {
    const header = ref<{
        open: boolean;
        sub: boolean;
        openMenu: string;
        chapmanLinks: ChapmanLink[];
    }>({
        open: false,
        sub: false,
        openMenu: '',
        chapmanLinks: [],
    });
    const footer = ref<{
        chapmanLinks: ChapmanLink[];
        memberLinks: ChapmanLink[];
    }>({
        chapmanLinks: [],
        memberLinks: [],
    });

    const navOpen = computed(() => {
        return header.value.open;
    });

    const RESET_ALL_NAV = () => {
        header.value.open = false;
        header.value.sub = false;
    };

    const TOGGLE_NAV = () => {
        header.value.open = !header.value.open;
    };

    const TOGGLE_SUB_NAV = () => {
        header.value.sub = !header.value.sub;
    };
    const CLOSE_NAV = () => {
        if (header.value.open) {
            header.value.open = false;
        }
    };

    const SET_SUB_MENU = (name: string) => {
        header.value.openMenu = name;
        if (header.value.openMenu.length) {
            header.value.sub = true;
        } else {
            header.value.sub = false;
        }
    };

    const CLEAR_SUB_MENU = () => {
        header.value.openMenu = '';
        header.value.sub = false;
    };

    return {
        header,
        footer,
        navOpen,
        RESET_ALL_NAV,
        TOGGLE_NAV,
        TOGGLE_SUB_NAV,
        CLOSE_NAV,
        SET_SUB_MENU,
        CLEAR_SUB_MENU,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useNavigationStore, import.meta.hot)
    );
}
